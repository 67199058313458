import { Alert, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Toast, Tooltip } from 'bootstrap';
import { stickynavbar } from './components/stickynavbar';
import { smoothscroll } from './components/smoothscroll';
import { scrolltopbutton } from './components/scrolltopbutton';
import { passwordvisibilitytoggle } from './components/passwordvisibilitytoggle';
import { swipercarousel } from './components/swipercarousel';
import { rangeslider } from './components/rangeslider';
import { formvalidation } from './components/formvalidation';
import { inputformatter } from './components/inputformatter';
export { Alert, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Toast, Tooltip, stickynavbar, smoothscroll, scrolltopbutton, passwordvisibilitytoggle, swipercarousel, rangeslider, formvalidation, inputformatter,
//We don't use the following so I didn't convert them yet: ak
//themeModeSwitch,
//elementParallax,
//parallax,
//gallery,
//videoButton,
//priceSwitch,
//masonryGrid,
//subscriptionForm,
//hoverAnimation,
//audioPlayer
 };
export class MantaVendor {
    constructor() {
        this.init();
    }
    init() {
        this.initAlerts();
        this.initCarousel();
        this.initCollapse();
        this.initDropDowns();
        this.initModals();
        this.initOffcanvas();
        this.initPopovers();
        this.initTabs();
        this.initToasts();
        this.initTooltips();
        stickynavbar.init();
        smoothscroll.init();
        scrolltopbutton.init();
        passwordvisibilitytoggle.init();
        swipercarousel.init();
        rangeslider.init();
        formvalidation.init();
        inputformatter.init();
    }
    initAlerts() {
        $('.alert').alert();
    }
    initCarousel() {
        $('[data-bs-toggle="carousel"]').carousel();
    }
    initCollapse() {
        $('[data-bs-toggle="collapse"]').collapse();
    }
    initDropDowns() {
        $('[data-bs-toggle="dropdown"]').dropdown();
    }
    initModals() {
        $('[data-bs-toggle="modal"]').each((i, e) => {
            $(e).modal({
                backdrop: true
            });
        });
    }
    initOffcanvas() {
        let offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'));
        let offcanvasList = offcanvasElementList.map((offcanvasEl) => {
            return new Offcanvas(offcanvasEl);
        });
    }
    initPopovers() {
        $('[data-bs-toggle="popover"]').popover();
    }
    initTabs() {
        $('[data-bs-toggle="tab"]').tab();
    }
    initToasts() {
        $('.toast').toast();
    }
    initTooltips() {
        $('[data-bs-toggle="tooltip"]').each((i, e) => {
            $(e).tooltip({ trigger: 'hover' });
        });
    }
}
